.container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.footerContent {
    width: 100%;
    display: flex;
    gap: 42px;
    padding: 20px;
    background-color: #313b44;
    justify-content: center;
}

.footerSections {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #fff;
}

.footerSections h1 {
    font-size: 22px;
}

.textGroup {
    display: flex;
    flex-direction: column;
}

.textGroup h2 {
    font-size: 18px;
}

.textGroup h3 {
    font-size: 16px;
    font-weight: 500;
}

.footerSections ul{
    padding-left: 20px;
}

.footerSections p {
    width: 250px;
}

.footerSections a {
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}

.socialMedia {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.copyrigth {
    width: 100%;
    display: flex;
    gap: 42px;
    padding: 16px;
    justify-content: center;
    font-weight: bold;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .footerContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 42px;
        padding: 64px;
        background-color: #313b44;
    }

    .socialMedia {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }
}