.contact-section {
  padding: 4em 0;
}

.wrapper {
  width: 100%;
  -webkit-box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
}

.contact-wrap {
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  background: rgb(21, 91, 182) 0%;
  background: -moz-linear-gradient(
    -45deg,
    rgb(21, 91, 182) 0%,
    rgb(144, 142, 172) 100%
  );
  background: -webkit-gradient(
    left top,
    right bottom,
    color-stop(0%, rgb(21, 91, 182)),
    color-stop(100%, rgb(144, 142, 172))
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgb(21, 91, 182) 0%,
    rgb(144, 142, 172) 100%
  );
  background: -o-linear-gradient(
    -45deg,
    rgb(21, 91, 182) 0%,
    rgb(144, 142, 172) 100%
  );
  background: -ms-linear-gradient(
    -45deg,
    rgb(21, 91, 182) 0%,
    rgb(144, 142, 172) 100%
  );
  background: linear-gradient(
    135deg,
    rgb(21, 91, 182) 0%,
    rgb(144, 142, 172) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7f279c', endColorstr='#2e279d', GradientType=1 );
  h4 {
    color: #fff;
  }
}

h4 {
  color: white;
}

.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.form-control {
  height: 62px;
  background: transparent;
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 16px;
  border-radius: 0px;
  box-shadow: none !important;
  border: 1px solid rgba(255, 255, 255, 0.2);
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.5) !important;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.5) !important;
  }
  &:-ms-input-placeholder {
    /* IE 0+ */
    color: rgba(255, 255, 255, 0.5) !important;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(255, 255, 255, 0.5) !important;
  }
  &:focus,
  &:active {
    background: transparent;
    border-color: rgba(232, 220, 220, 0.5) !important;
  }
}

textarea.form-control {
  height: inherit !important;
  margin-top: 5px;
}

.contactForm {
  .form-control {
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0;
  }
}

.btn {
  padding: 12px 16px;
  cursor: pointer;
  border-width: 1px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
  position: relative;
  margin-top: 20px;
}

.btn-primary {
  background: rgb(255, 255, 255) !important;
  border-color: rgb(255, 255, 255) !important;
  color: #000000;
}

.info-wrap {
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  h4 {
    color: #000;
    position: relative;
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 60px;
      height: 3px;
      background: #2745db;
      content: "";
    }
  }
  .dbox {
    width: 100%;
    // color: rgba(255,255,255,.8);
    margin-bottom: 25px;
    &:last-child() {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
      font-size: medium;
      span {
        font-weight: 400;
        color: #000;
      }
      a {
        color: rgba(0, 0, 0, 0.3);
      }
    }
    .icon {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.05);
      span {
        font-size: 20px;
        color: #000;
      }
    }
    .text {
      width: calc(100% - 50px);
    }
  }
  p {
    font-size: smaller;
  }
  .row {
    .col {
      .row {
        a {
          font-size: xx-large;
          transition: 0.3s all ease;
          color: #646060;
          text-decoration: none;
          &:hover,
          &:focus {
            text-decoration: none !important;
            outline: none !important;
            box-shadow: none;
            color: #000;
          }
        }
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  line-height: 1.5;
  font-weight: 400;
  font-family: "Poppins", Arial, sans-serif;
  color: #000;
}

a {
  transition: 0.3s all ease;
  color: #d62196;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none !important;
    outline: none !important;
    box-shadow: none;
  }
}

#contactForm {
  .form-control {
    font-size: 16px;
  }
}

.mycontent-right {
  border-right: 1px solid #d6d6d6;
}
