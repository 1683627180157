.container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    align-items: center;
    height: 70px;
    z-index: 1000;
    /* background: #fff; */
    background: #EBF4F6;
}

.logo {
    position: absolute;
    height: 40px;
    width: auto;
    left: 16px;
}

.container input {
    display: none;
}

.menuIcon {
    display: none;
}

.menuContainer {
    display: flex;
    gap: 32px;
    width: auto;
}

.itemMenu {
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    width: auto;
}

.itemMenu:hover > .active, .itemMenu:hover > .disable {
    background-color: #091f46;
}

.itemMenu:hover > h1 {
    color: #091f46;
}
.itemMenu:hover > span {
    color: #091f46;
}

.itemMenu h1.clicked::after {
    color: black;
}

.itemMenu h1 {
    font-size: 19px;
    color: #37729c;
    font-weight: bolder;
    font-family: "Madefor-Regular";
}

.active {
    width: 40%;
    height: 5px;
    background-color: #37729c;
    border-radius: 8px;
}

.disable {
    width: 40%;
    height: 5px;
    background-color: transparent;
    border-radius: 8px;
}

@media only screen and (max-width: 1080px) {
    .menuIcon {
        display: block;
        position: absolute;
        height: 40px;
        width: auto;
        top: 15px;
        right: 16px;
        cursor: pointer;
        filter: invert(100%) opacity(50%);
    }

    .menuContainer {
        display: none;
    }

    input:checked  ~ .menuContainer{
        display: flex;
        position: fixed;
        width: 100%;
        top: 70px;
        background-color: #313b44a6;
        flex-direction: column;
        height: 100vh;
        gap: 0;
        backdrop-filter: blur(6px);
    }

    .itemMenu {
        padding: 16px;
        border-bottom: 1px solid #313b44;
    }

    .itemMenu h1 {
        color: #fff;
    }

    .itemMenu:hover {
        background-color: #313b449a;
    }

    .itemMenu:hover > h1 {
        color: #37729c;
    }

    .active {
        display: none;
    }
    
    .disable {
        display: none;
    }
}