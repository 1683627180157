.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 70px;
}

.whoAreSection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 36px;
  padding: 32px;
  background: #EBF4F6;
  /* background: #313b44; */
}

.whoAreSection img {
  animation: transitionWhoAre linear both;
  animation-timeline: view();
  animation-range: entry 20% cover 30%;
}

.whoAreSectionInfo {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: normal;
  gap: 20px;
  animation: transitionWhoAre linear both;
  animation-timeline: view();
  animation-range: entry 20% cover 30%;
  color: #fff;
}

@keyframes transitionWhoAre {
  from {
    opacity: 0%;
    translate: 0 200px;
  }
  to {
    opacity: 100%;
    translate: 0 0;
  }
}

.whoAreSectionInfo h1 {
  font-size: 42px;
  color: #37729c;
  text-align: left;
  font-family: "Madefor-Bold";
}

.whoAreSectionInfo p {
  text-align: left;
  font-family: "Madefor-Regular";
  color: black;
}

.serviceSection {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 200px);
  gap: 36px;
  justify-content: center;
  padding: 32px;
  background-image: url(../../assets/images/DNA-background.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.serviceSectionInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.serviceSectionInfo img {
  width: 100%;
}

.serviceSectionInfo h1 {
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: #37729c;
  font-family: "Madefor-Regular";
  font-weight: bolder;
}

.serviceSectionInfo p {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: white;
  font-family: "Madefor-Regular";
}

.howWeWorkSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 32px;
  background: #313b44;
  color: #fff;
}

.howWeWorkContent {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 200px);
  gap: 36px;
  justify-content: center;
}

.howWeWorkInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding-top: 20px;
}

.howWeWorkInfo:nth-child(1) {
  animation: transitioHowWeWorkInfo1 linear both;
  animation-timeline: view();
  animation-range: entry 20% cover 30%;
}

.howWeWorkInfo:nth-child(2) {
  animation: transitioHowWeWorkInfo2 linear both;
  animation-timeline: view();
  animation-range: entry 20% cover 30%;
}

.howWeWorkInfo:nth-child(3) {
  animation: transitioHowWeWorkInfo3 linear both;
  animation-timeline: view();
  animation-range: entry 20% cover 30%;
}

.howWeWorkInfo:nth-child(4) {
  animation: transitioHowWeWorkInfo4 linear both;
  animation-timeline: view();
  animation-range: entry 20% cover 30%;
}

@keyframes transitioHowWeWorkInfo1 {
  from {
    opacity: 0%;
    translate: -50px 0;
  }
  to {
    opacity: 100%;
    translate: 0 0;
  }
}

@keyframes transitioHowWeWorkInfo2 {
  from {
    opacity: 0%;
    translate: -25px 0;
  }
  to {
    opacity: 100%;
    translate: 0 0;
  }
}

@keyframes transitioHowWeWorkInfo3 {
  from {
    opacity: 0%;
    translate: 25px 0;
  }
  to {
    opacity: 100%;
    translate: 0 0;
  }
}

@keyframes transitioHowWeWorkInfo4 {
  from {
    opacity: 0%;
    translate: 50px 0;
  }
  to {
    opacity: 100%;
    translate: 0 0;
  }
}

.howWeWorkInfo img {
  height: 300px;
}

.howWeWorkInfo h1 {
  width: 100%;
  text-align: center;
  font-size: 20px;
}

.howWeWorkInfo p {
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.contactUsSection {
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.contactUsSectionContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 34px;
  background-color: #313b4457;
  border-radius: 10px;
  color: #fff;
}

.alliesSection {
  width: 100%;
  /*    padding: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 1em 0;
}

.alliesSection h1 {
  color: #37729c;
  text-align: center;
}

.alliesContent {
  display: flex;
  flex-direction: column;
  padding: 32px;
  border: 1px solid #313b44;
  gap: 34px;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  border-radius: 10px;
}

.alliesContent h2 {
  font-size: 16px;
  font-weight: 600;
}

.alliesImages {
  display: flex;
  gap: 4px;
}

.alliesImages img {
  max-width: 100px;
  max-height: 100px;
  transition-property: scale;
  transition-duration: 0.5s;
}

.alliesImages img:hover {
  scale: 1.2;
}

@media only screen and (max-width: 1080px) {
  .howWeWorkContent {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 200px);
    gap: 36px;
    justify-content: center;
  }

  .serviceSection {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 200px);
    gap: 36px;
    justify-content: center;
    padding: 32px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media only screen and (max-width: 768px) {
  .whoAreSection {
    flex-direction: column;
    align-items: center;
  }

  .whoAreSectionInfo {
    flex-direction: column;
    align-items: center;
  }

  .whoAreSectionInfo p {
    text-align: left;
  }

  .howWeWorkContent {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 200px);
    gap: 36px;
    justify-content: center;
  }

  .serviceSection {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 200px);
    gap: 36px;
    justify-content: center;
    padding: 32px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.highlight {
  font-size: 17px;
  font-family: "Madefor-Bold";
  font-weight: bolder;
}

.scrollStyle {
  overflow: hidden;
}