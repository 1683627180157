@font-face {
  font-family: "Madefor-Regular";
  src: url("./fonts/Wix_Madefor/WixMadeforText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Madefor-Bold";
  src: url("./fonts/Wix_Madefor/WixMadeforText-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-size: 16px;
  /* line-height: 1.8; */
  font-weight: normal;
  background: #fafafa;
  color: lighten(#000, 40%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}
