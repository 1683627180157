.HeaderSection {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: white;
}

.Content {
  vertical-align: middle;
  position: absolute;
  padding: 25px;
  h1 {
    font-family: "Madefor-Bold";
    color: white;
    line-height: 0.9;
    margin-block-end: 0px;
  }
  p {
    font-family: "Madefor-Regular";
    font-size: x-large;
  }
}

.Flip{
  position: relative;
  display: block;
  overflow: hidden;
}

.Flip2{
  top: auto;
  position: absolute;
  inset: 0;
}

.Inline{
  display: inline-block;
  vertical-align: middle;
}

.Inline1{
  width: 20px;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide {
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;
}

.slide img {
  height: 800px;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 400px;
}

.info h1 {
  font-size: 54px;
  color: #37729c;
}

.info h2 {
  font-size: 22px;
  color: #606467;
}

@media only screen and (max-width: 1080px) {
  .HeaderSection {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: white;
  }

  .slide {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
  }

  .slide img {
    height: 400px;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    max-width: 400px;
  }

  .info h1 {
    font-size: 32px;
    color: #37729c;
    text-align: center;
  }

  .info h2 {
    font-size: 20px;
    color: #606467;
  }
}

@media only screen and (max-width: 1080px) {
  .Flip{
    position: relative;
    display: block;
    overflow: hidden;
  }
  
  .Flip2{
    display: none;
  }

  .Inline{
    display: inline;
    vertical-align: middle;
    // font-size: 31px;
  }
  
  .Inline1{
    display: inline;
  }

  // .HeaderSection p{
  //   font-size: 18px;
  // }
}

// @media only screen and (max-width: 900px) {
//   .Flip{
//     position: relative;
//     display: block;
//     overflow: hidden;
//   }
  
//   .Flip2{
//     display: none;
//   }

//   .Inline{
//     display: inline;
//     vertical-align: middle;
//     // font-size: 20px;
//   }
  
//   .Inline1{
//     display: inline;
//   }

//   // .HeaderSection p{
//   //   font-size: 15px;
//   // }
// }